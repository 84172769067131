import Vue from 'vue'
import Vuex from 'vuex'
// @ts-ignore 声明文件
import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import user from './modules/user'
import userUses from './modules/userUses'
import data from './modules/data'
import advert from './modules/advert'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['user.token', 'userUses.uses' ]
  })],
  modules: {
    app,
    user,
    userUses,
    data,
    advert
  }
})
