import Vue from 'vue'

interface UserUseOpts {
  userId?: string
  levelStandard?: State.UserUseItem
  formulaStandard?: State.UserUseItem
}

const state: State.UserUses = {
  uses: []
}

const getters = {
  getUserUse (state: State.UserUses, getters: any, rootState: any) {
    return state.uses.find(item => item.userId === rootState.user.userId)
  },
  getUserUseByUserId: (state: State.UserUses, rootState: any) => (userId: string = rootState.user.userId) => {
    // 默认获取已登录用户信息
    return state.uses.find(item => item.userId === userId)
  },
  getLevelStandard (state: State.UserUses, getters: any, rootState: any) {
    let use = getters.getUserUse
    if (use && use.levelStandard) {
      return use.levelStandard
    }
    return undefined
  },
  getFormulaStandard (state: State.UserUses, getters: any, rootState: any) {
    let use = getters.getUserUse
    if (use && use.formulaStandard) {
      return use.formulaStandard
    }
    return undefined
  }
}

const mutations = {
  setUserUse (state: State.UserUses, opts: UserUseOpts) {
    // 暂开放设置当前登录用户信息设置，不能跨用户设置
    log.debug('[UserUses setUserUse] opts.userId:', opts.userId)
    let userUse = state.uses.find(item => item.userId === opts.userId)
    if (userUse === undefined && opts.userId !== undefined) {
      // 用户信息不存在，新增用户信息
      state.uses.push({ userId: opts.userId })
      userUse = state.uses.find(item => item.userId === opts.userId)
    }
    if (opts.levelStandard !== undefined && userUse !== undefined) {
      Vue.set(userUse, 'levelStandard', opts.levelStandard)
      // userUse.standard = opts.standard
    }
    if (opts.formulaStandard !== undefined && userUse !== undefined) {
      Vue.set(userUse, 'formulaStandard', opts.formulaStandard)
      // userUse.standard = opts.standard
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
