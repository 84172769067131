class Polyfill {
  constructor () {
    // if (!String.prototype.endsWith) {
    //   String.prototype.endsWith = function (search, thisLen) {
    //     if (thisLen === undefined || thisLen > this.length) {
    //       thisLen = this.length
    //     }
    //     return (this.substring(thisLen - search.length, thisLen) === search)
    //   }
    // }
    if (NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window
        for (let i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this)
        }
      }
    }
  }
}
export default new Polyfill()
