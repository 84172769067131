





















































































































































































import { Component, Vue, Inject } from 'vue-property-decorator'
import advert from '@/components/Advert/Advert.vue'

@Component({ components: { advert } })
export default class Product extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  staticPath: string = 'https://s.doumiao9.com/official'
  scrollY: number = 0
  to (path: string) {
    Lib.router.push(path)
  }
  back () {
    Lib.router.go(-1)
  }
  download (path: string) {
    window.open(path)
  }
  created () {
    this.loaded = true
  }
  scrollTop () {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
  mounted () {
    console.log()
    this.scrollTop()
    Lib.dispatchEvent(document, 'custom-render-trigger')
  }
}
