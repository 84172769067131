



























import { Component, Vue, Inject } from 'vue-property-decorator'
import advert from '@/components/Advert/Advert.vue'

@Component({ components: { advert } })
export default class Contact extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  to (path: string) {
    Lib.router.push(path)
  }
  created () {
    this.loaded = true
  }
  mounted () { Lib.dispatchEvent(document, 'custom-render-trigger') }
}
