




import { Component, Vue, Provide } from 'vue-property-decorator'
import { ElLoadingComponent } from 'element-ui/types/loading'
import AppTouch from '@/components/AppTouch/AppTouch.vue'
import AppHead from '@/components/AppHead/AppHead.vue'
import AppFoot from '@/components/AppFoot/AppFoot.vue'
import Reservation from '@/components/Reservation/Reservation.vue'

// 公共组件初始化
Vue.component('app-touch', AppTouch)
Vue.component('app-head', AppHead)
Vue.component('app-foot', AppFoot)
Vue.component('reservation', Reservation)

@Component
export default class App extends Vue {
  isRouterAlive: boolean = true
  // 刷新页面，不会清空内存信息
  @Provide()
  appReload () {
    this.isRouterAlive = false
    this.$nextTick(() => (this.isRouterAlive = true))
  }
  // 身份验证
  @Provide()
  appAuth (silent?: boolean) {
    return new Promise((resolve, reject) => {
      // 未登录，检测本地是否保存有登录状态
      log.debug(`[App auth] Lib.store.state.user.token: ${Lib.store.state.user.token}`)
      let loading: ElLoadingComponent | null = null
      if (!silent) {
        loading = this.$loading({
          spinner: 'app-loading-spinner',
          text: '加载中'
        })
      }
      if (!Lib.store.state.user.userId) {
        log.debug('[App auth] state 中没有userId, 检测用户是否已经在登录中心登录')
        Lib.store.dispatch('user/check').then(() => {
          log.debug('[App auth] 用户已在登录中心登录')
          if (loading) { loading.close() }
        }).catch(() => {
          log.debug('[App auth] 用户未登录')
          let query: any = {}
          query.redirect = this.$route.fullPath
          Lib.router.replace({
            path: '/login/',
            query
          })
          if (loading) { loading.close() }
          resolve()
        })
      } else {
        if (loading) { loading.close() }
        resolve()
      }
    })
  }
}
