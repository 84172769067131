/**
 * 日志输出工具类
 * @version 1.0.2
 * @author JQ<jianqingchina@qq.com>
 * 2017/05/06 - 2018/02/08
 */
// import Raven from 'raven-js'

export default class Log {
  log (...args: any[]) {
    console.log.apply(console, args)
    // if (window.Raven) {
    //   let opts: Raven.RavenOptions = { level: 'log' }
    //   window.Raven.captureMessage(args.join(', '), opts)
    // }
  }
  info (...args: any[]) {
    console.info.apply(console, args)
    // if (window.Raven) {
    //   let opts: Raven.RavenOptions = { level: 'info' }
    //   window.Raven.captureMessage(args.join(', '), opts)
    // }
  }
  debug (...args: any[]) {
    console.debug.apply(console, args)
    // if (window.Raven) {
    //   let opts: Raven.RavenOptions = { level: 'debug' }
    //   window.Raven.captureMessage(args.join(', '), opts)
    // }
  }
  warn (...args: any[]) {
    console.warn.apply(console, args)
    // if (window.Raven) {
    //   let opts: Raven.RavenOptions = { level: 'warn' }
    //   window.Raven.captureMessage(args.join(', '), opts)
    // }
  }
  error (...args: any[]) {
    console.error.apply(console, args)
    // if (window.Raven) {
    //   let opts: Raven.RavenOptions = { level: 'error' }
    //   window.Raven.captureException(args.join(', '), opts)
    // }
  }
  trace (...args: any[]) { console.trace.apply(console, args) }
}
