import { render, staticRenderFns } from "./Chain.vue?vue&type=template&id=2e39eaaa&scoped=true&"
import script from "./Chain.vue?vue&type=script&lang=ts&"
export * from "./Chain.vue?vue&type=script&lang=ts&"
import style0 from "./Chain.vue?vue&type=style&index=0&id=2e39eaaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e39eaaa",
  null
  
)

component.options.__file = "Chain.vue"
export default component.exports