



























































































































import { Component, Vue, Inject } from 'vue-property-decorator'
import advert from '@/components/Advert/Advert.vue'

@Component({ components: { advert } })
export default class Product extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  staticPath: string = '/'
  mediaPath: string = 'http://s.doumiao9.com/official/dmqzxs/media/'
  mediaName: string = 'result-1'
  scrollY: number = 0
  to (path: string) {
    Lib.router.push(path)
  }
  back () {
    Lib.router.go(-1)
  }
  download (path: string) {
    window.open(path)
  }
  created () {
    this.loaded = true
  }
  scrollTop () {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
  mounted () {
    console.log()
    this.scrollTop()
    let close: any = document.querySelector('.close-btn')
    let video: any = document.querySelector('.target')
    let videoBox: any = document.querySelector('.video-box')
    if (video) { video.pause() }
    if (close) {
      close.addEventListener('click', () => {
        if (video && videoBox) {
          video.pause()
          videoBox.classList.add('none')
        }
      })
    }
    let itemArr = document.querySelectorAll('.ava-item')
    if (itemArr.length) {
      itemArr.forEach(item => {
        let img = item.querySelector('img')
        item.addEventListener('click', () => {
          if (videoBox && img) {
            // this.mediaName = img.alt
            video.src = this.mediaPath + img.alt + '.mp4'
            video.play()
            videoBox.classList.remove('none')
          }
        })
      })
    }

    let linkViewList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.link-view')
    if (linkViewList && linkViewList.length) {
      linkViewList.forEach(ele => {
        let img = ele.querySelector('.link-view-img')
        if (!img) { return }
        let src = img.getAttribute('src')
        if (!src) { return }
        ele.href = src
      })
    }

    Lib.dispatchEvent(document, 'custom-render-trigger')
  }
}
