import Vue from 'vue'
import Router from 'vue-router'
import { Route } from '../node_modules/vue-router/types'
import Index from './views/Index.vue'
import Download from './views/Download.vue'
import About from './views/About.vue'
import School from './views/School.vue'
import Chain from './views/Chain.vue'
import Contact from './views/Contact.vue'
import FQA from './views/FQA.vue'
import Photo from './views/photo.vue'

Vue.use(Router)

// let redirect = (to: Route, from: Route, next: any) => {
//   next({ path: '/product/', replace: true })
// }
let recordAccessHook = (to: Route, from: Route, next: any) => {
  // let routerEvent = Lib.router.recordEvent
  log.debug('[Router recordAccessHook]')
  if (Lib.store.state.user.token) {
    log.debug('[Router recordAccessHook] 用户已登录')
    setTimeout(() => {
      SApi.recordAccess({
        gameName: 'official',
        gameVersion: app.release,
        gameParams: JSON.stringify({
          path: to.path,
          query: to.query
        }),
        timestamp: new Date().getTime()
      }).catch()
    }, 0)
  }
  next()
}

export default new Router({
  mode: 'history',
  // base: '/',
  routes: [
    { path: '/', name: 'index', component: Index, beforeEnter: recordAccessHook },
    { path: '/school/', name: 'school', component: School, beforeEnter: recordAccessHook },
    { path: '/fqa/', name: 'fqa', component: FQA, beforeEnter: recordAccessHook },
    { path: '/download/', name: 'download', component: Download, beforeEnter: recordAccessHook },
    { path: '/about/', name: 'about', component: About, beforeEnter: recordAccessHook },
    { path: '/contact/', name: 'contact', component: Contact, beforeEnter: recordAccessHook },
    { path: '/chain/', name: 'chain', component: Chain, beforeEnter: recordAccessHook },
    { path: '/photo/', name: 'photo', component: Photo, beforeEnter: recordAccessHook }
  ]
})
