const state: State.App = {
  findUpdate: false
}

const mutations = {
  set (state: State.App, vals: any) {
    if (vals === undefined) { return }
    if (vals.findUpdate !== undefined) { state.findUpdate = vals.findUpdate }
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
