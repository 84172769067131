





























































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Reservation extends Vue {
  @Prop({ default: '/' }) active!: string
  @Prop({ default: 'on' }) activeClassName!: string
  showDate: boolean = false
  RunNian: boolean = false
  schoolName: any = {
    'jinshangu': '广州番禺金山谷旗舰店',
    'shiqiao': '广州番禺市桥旗舰店',
    'liwanguanggang': '广州荔湾广钢校区',
    'aoyuan': '广州番禺奥园校区',
    'lanwan': '青岛李沧越秀星汇蓝湾旗舰店',
    'bananxinglanhui': '重庆巴南区星澜汇校区',
    'bananwanda': '重庆市巴南区万达校区',
    'huanyu': '佛山南海桂城环宇校区',
    'jiujiang': '佛山南海九江校区',
    'tianhong': '佛山禅城天虹校区',
    'jinshazhou': '佛山南海金沙洲校区',
    // 'shangdong': '广州番禺尚东校区',
    'shuangyashan': '双鸭山校区',
    'putian': '莆田校区',
    // 'dongexian': '山东聊城东阿县校区',
    'gulinxian': '泸州古蔺县校区',
    // 'puyang': '濮阳校区',
    'hainanzhou': '青海海南藏族自治州校区',
    'suqian': '宿迁校区',
    'suqiansucheng': '宿迁宿城校区',
    // 'shuguo': '重庆市江津区蜀果校区',
    // 'jinjianggagwan': '重庆市江津区金江港湾校区',
    'gaotangxian': '山东聊城高唐县校区',
    'longkou': '山东省烟台市龙口校区',
    'baoding': '河北省保定市唐县校区',
    'hengshui': '河北省衡水校区',
    // 'zhanjiangchikan': '湛江赤坎校区',
    // 'foshanpingzhou': '佛山平洲校区',
    // 'dongguandongcheng': '东莞东城校区',
    'dongguannancheng': '东莞南城校区'
  }
  to (path: string) {
    Lib.router.push(path)
  }
  submitFromData () {
    console.log('dianji')
    let input1: any = document.querySelector('.input1')
    let input2: any = document.querySelector('.input2')
    let input3: any = document.querySelector('.input3')
    let input4: any = document.querySelector('.input4')
    if (input1 && input2 && input3 && input4) {
      let name = input1.value
      let birthday = input2.value
      let phone = input3.value
      let key = input4.value
      let shop = this.schoolName[key] || ''
      // console.log(name, birthday, phone, shop)
      if (!name) {
        alert('姓名不能为空')
        return
      }
      if (!birthday) {
        alert('生日不能为空')
        return
      }
      if (!phone) {
        alert('电话不能为空')
        return
      }
      if (!shop) {
        alert('请选择预约门店')
        return
      }
      let data: SApiIF.ReservationParam = {
        realname: name,
        birthday: birthday,
        mobile: phone,
        schoolKey: key,
        schoolName: shop,
        dataFrom: 'dmqzxs-official'
      }
      // console.log(data)
      SApi.Reservation(data).then((res: any) => {
        console.log(res)
        alert(res.msg)
      }).catch(err => {
        alert(err)
      })
    }
  }
  addDate (days: number) {
    let day: any = document.querySelector('.day')
    day.length = 0
    for (let i = 1; i <= days; i++) {
      let opp = new Option(i.toString(), i.toString())
      day.add(opp)
    }
  }
  mounted () {
    let cover: any = document.querySelector('.cover')
    let input1: any = document.querySelector('.input1')
    let input2: any = document.querySelector('.input2')
    let input3: any = document.querySelector('.input3')
    let input4: any = document.querySelector('.input4')
    let year: any = document.querySelector('.year')
    let month: any = document.querySelector('.month')
    let day: any = document.querySelector('.day')
    let button = document.querySelector('.button')
    button!.addEventListener('click', this.submitFromData)
    let datePick: any = document.querySelector('.dateInput')
    let date: any = document.querySelector('.input2')
    let right: any = document.querySelector('.right')
    if (date) {
      date.addEventListener('focus', () => {
        datePick.classList.remove('none')
        cover.style.zIndex = 1
        this.addDate(31)
      })
    }
    if (right) {
      right.addEventListener('click', () => {
        datePick.classList.add('none')
        cover.style.zIndex = 1
        let months = ''
        let days = ''
        if (parseInt(month.value, 10) < 10) {
          months = '0' + month.value
        }
        if (parseInt(day.value, 10) < 10) {
          days = '0' + day.value
        }
        input2.value = year.value + '-' + months + '-' + days
      })
    }
    for (let i = 1999; i <= 2030; i++) {
      let opp = new Option(i.toString(), i.toString())
      year.add(opp)
    }
    for (let i = 1; i <= 12; i++) {
      let opp = new Option(i.toString(), i.toString())
      month.add(opp)
    }
    year.addEventListener('change', () => {
      let years = parseInt(year.value, 10)
      if (years % 4 === 0 && years % 100 !== 0 || years % 400 === 0) {
        this.RunNian = true
      }
    })
    month.addEventListener('change', () => {
      let minMonth = ['4', '6', '9', '11']
      let years = parseInt(year.value, 10)
      if (years % 4 === 0 && years % 100 !== 0 || years % 400 === 0) {
        if (month.value === '2') {
          this.addDate(29)
        }
      } else {
        if (month.value === '2') {
          this.addDate(28)
        }
      }
      if (minMonth.includes(month.value)) {
        this.addDate(30)
      }
    })
    if (cover) {
      cover.addEventListener('click', () => {
        cover.style.zIndex = -1
        datePick.classList.add('none')
      })
    }
  }
}
