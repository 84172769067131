const state: State.User = {
  token: '',
  userId: '',
  mobile: '',
  realname: '',
  identity: ''
}

const mutations = {
  clear (state: State.User) {
    state.token = ''
    state.userId = ''
    state.mobile = ''
    state.realname = ''
    state.identity = ''
    Lib.cookie.remove('_dm_token')
    log.debug('[store user.clear] remove cookie')
  },
  set (state: State.User, vals: any) {
    if (vals === undefined) { return }
    if (vals.token !== undefined) { state.token = vals.token }
    if (vals.userId !== undefined) { state.userId = vals.userId }
    if (vals.mobile !== undefined) { state.mobile = vals.mobile }
    if (vals.realname !== undefined) { state.realname = vals.realname }
    if (vals.identity !== undefined) { state.identity = vals.identity }
  }
}

const actions = {
  login ({ state, commit, dispatch }: {state: State.User, commit: any, dispatch: any}, params: any) {
    return new Promise((resolve, reject) => {
      log.debug(`[user actions] params: "${params}"`)
      SApi.User.login(params).then((data: any) => {
        commit('set', { token: data.token })
        let setCookie = Lib.cookie.set('_dm_token', data.token)
        log.debug('[store user.login] set cookie _dm_token:', setCookie)
        return dispatch('info')
      }).then(resolve).catch((ex: string) => {
        // 登录过程有一个报错即清空状态，让用户重新登录
        commit('clear')
        reject(ex)
      })
    })
  },
  logout ({ commit }: { commit: any }) {
    return new Promise((resolve, reject) => {
      SApi.User.logout().then(data => {
        commit('clear')
        resolve(data)
      }).catch(reject)
    })
  },
  info ({ state, commit }: { state: State.User, commit: any }) {
    return new Promise((resolve, reject) => {
      SApi.User.userInfo().then(data => {
        commit('set', {
          userId: data.ssoAuth.userId,
          mobile: data.ssoAuth.mobile,
          realname: data.ssoAuth.realname,
          identity: data.ssoAuth.identity
        })
        resolve()
      }).catch(reject)
    })
  },
  check ({ state, commit, dispatch }: {state: State.User, commit: any, dispatch: any}) {
    return new Promise((resolve, reject) => {
      let dispatchInfo = null
      let cookieToken = Lib.cookie.get('_dm_token')
      if (cookieToken) {
        commit('set', { token: cookieToken })
        dispatchInfo = dispatch('info')
      } else {
        let query: SApiIF.User.CheckParam = {}
        if (state.token) { query.token = state.token }
        dispatchInfo = SApi.User.userCheck(query).then(data => {
          commit('set', { token: data.token })
          let setCookie = Lib.cookie.set('_dm_token', data.token)
          log.debug('[store user.check] set cookie _dm_token:', setCookie)
          return dispatch('info')
        })
      }
      dispatchInfo.then(resolve).catch((ex: string) => {
        // 登录过程有一个报错即清空状态，让用户重新登录
        commit('clear')
        reject(ex)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
