/**
 * cordova 扩展类
 * 自定义一些cordova相关的方法，cordova自身的window.cordova 方法不一样，不冲突
 * @version 1.0.2
 * @author JQ<jianqingchina@qq.com>
 * 2017/12/05 - 2018/01/20
 */
import LProxy from './lProxy'
import CordovaAudio from './cordovaAudio'

export default class Cordova {
  version: string = ''
  Audio = CordovaAudio
  private readyResolve: Function | null = null
  private isReady: boolean = false
  constructor () {
    this.onDeviceReady = LProxy.proxy(this.onDeviceReady, this)
    document.addEventListener('deviceready', this.onDeviceReady)
  }
  /**
   * cordova 设备准备完成，需要用到的插件也准备完成
   */
  ready () {
    return new Promise((resolve, reject) => {
      if (this.isReady) { return resolve() }
      this.readyResolve = resolve
    })
  }
  private loadDone () {
    this.isReady = true
    if (this.readyResolve) { this.readyResolve() }
  }
  private onDeviceReady () {
    log.debug('[Lib Cordova onDeviceReady] device was ready.')
    this.debug()
    window.cordova.getAppVersion.getVersionNumber().then((version: string) => {
      this.version = version
      this.loadDone() // 需要再嵌入异步事件，仅需把此代码移到异步事件完全处理完之后运行
    })
  }
  private debug () {
    // 以下为兼容的代码
    if (window.app.platform === 'browser') {
      window.cordova.getAppVersion.getVersionNumber = () => {
        return new Promise((resolve, reject) => {
          resolve('1.0.0')
        })
      }
    }
  }
}
