/**
 * 深复制（数组、对象）工具类
 * @version 1.0.1
 * @author JQ<jianqingchina@qq.com>
 * https://gist.github.com/tlecreate/5655855628c2b53cead9884e0a127cb7
 * 2017/12/16 - 2018/02/08
 */
export default class DeepCopy {
  static copy<T> (data: T): T {
    let node: any
    if (Array.isArray(data)) {
      node = data.length > 0 ? data.slice(0) : []
      node.forEach((e: any, i: any) => {
        if (
            (typeof e === 'object' && e !== {}) ||
            (Array.isArray(e) && e.length > 0)
        ) {
          node[i] = DeepCopy.copy(e)
        }
      })
    } else if (typeof data === 'object') {
      node = Object.assign({}, data)
      Object.keys(node).forEach((key) => {
        if (
            (typeof node[key] === 'object' && node[key] !== {}) ||
            (Array.isArray(node[key]) && node[key].length > 0)
        ) {
          node[key] = DeepCopy.copy(node[key])
        }
      })
    } else {
      node = data
    }
    return node
  }
}
