import { render, staticRenderFns } from "./Download.vue?vue&type=template&id=1aad0954&scoped=true&"
import script from "./Download.vue?vue&type=script&lang=ts&"
export * from "./Download.vue?vue&type=script&lang=ts&"
import style0 from "./Download.vue?vue&type=style&index=0&id=1aad0954&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aad0954",
  null
  
)

component.options.__file = "Download.vue"
export default component.exports