
































import { Component, Vue, Inject } from 'vue-property-decorator'
import advert from '@/components/Advert/Advert.vue'

@Component({ components: { advert } })
export default class Photo extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  staticPath: string = 'https://s.doumiao9.com/official'
  scrollY: number = 0
  imgNum: number = 1
  to (path: string) {
    Lib.router.push(path)
  }
  download (path: string) {
    window.open(path)
  }
  created () {
    this.loaded = true
  }
  scrollTop () {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
  mounted () {
    this.scrollTop()
    Lib.dispatchEvent(document, 'custom-render-trigger')
    let imgArr = document.querySelectorAll('.feature-img')
    let pageImg = document.querySelector('.page-img') as HTMLImageElement
    let page = document.querySelector('.page')
    let close = document.querySelector('.close')
    let iconLeft = document.querySelector('.el-icon-arrow-left')
    let iconRight = document.querySelector('.el-icon-arrow-right')
    if (imgArr.length) {
      imgArr.forEach(img => {
        img.addEventListener('click', () => {
          if (img) {
            this.imgNum = Number(img.className.split('img-')[1])
            pageImg.src = require('../assets/feature/' + this.imgNum + '.jpg')
            page!.classList.add('show')
          }
        })
      })
    }
    if (close) {
      close.addEventListener('click', () => {
        page!.classList.remove('show')
      })
    }
    if (iconLeft) {
      iconLeft.addEventListener('click', () => {
        if (this.imgNum <= 1) {
          return
        }
        this.imgNum -= 1
        pageImg.src = require('../assets/feature/' + this.imgNum + '.jpg')
      })
    }
    if (iconRight) {
      iconRight.addEventListener('click', () => {
        if (this.imgNum >= 51) {
          return
        }
        this.imgNum += 1
        pageImg.src = require('../assets/feature/' + this.imgNum + '.jpg')
      })
    }
  }
}
