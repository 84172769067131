









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Advert2 extends Vue {
  mounted () {
    // this.$store.commit()
    let content = document.querySelector('.advert6-content')
    let close = document.querySelector('.advert6-close')
    // let data = this.$store.state.advert6.query
    // console.log(this.$store.state)
    // if (data && data.click && content) { // 广告点击关闭后就不再显示
    //   content.classList.add('close')
    // }
    if (close) {
      close.addEventListener('click', () => {
        if (content) {
          content.classList.add('close')
          // this.$store.commit('advert6/setQuery', { click: true })
        }
      })
    }
  }
}
