import Vue from 'vue'
const state: State.advert = {
  query: null
}

const mutations = {
  setQuery (state: any, opt: any) {
    log.debug('[Store.Advert setQuery] opt:', opt)
    if (state.query === null) { Vue.set(state, 'query', {}) }
    Object.keys(opt).forEach(key => {
      Vue.set(state.query, key, opt[key])
    })
  },
  clearQuery () {
    log.debug('[Store.Advert clearQuery]')
    Vue.set(state, 'query', null)
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
