

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AppHead extends Vue {
  @Prop({ default: '/' }) active!: string
  @Prop({ default: 'on' }) activeClassName!: string
  to (path: string) {
    Lib.router.push(path)
  }
}
